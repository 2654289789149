export const colors = {
    primary: "#850B0A",     // Immobiliare main dark red color
    secondary: "#4286DF",   // Immobiliare secondary blue color
    background: "#F8F9FA",  // Light neutral background
    surface: "#FFFFFF",     // Pure white surface
    textPrimary: "#333333", // Dark gray for primary text
    textSecondary: "#666666", // Medium gray for secondary text
    accent: "#F4A261",      // Warm orange for highlights or buttons
    success: "#2A9D8F",     // Teal for success states
    warning: "#E9C46A",     // Muted yellow for warnings
    error: "#E76F51",       // Soft red for error states
    info: "#8D99AE",        // Cool grayish-blue for informational elements
}

/**
 * Convert hex color string to RGB array for deck.gl
 * @param hex Hex color string (e.g. "#2A9D8F")
 * @param alpha Optional alpha value (0-255)
 * @returns RGB or RGBA array [r, g, b] or [r, g, b, a]
 */
export const hexToRgb = (hex: string, alpha?: number): number[] => {
    // Remove # if present
    hex = hex.replace('#', '');
    
    // Parse hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    // Return RGB or RGBA array
    return alpha !== undefined ? [r, g, b, alpha] : [r, g, b];
};

// Pre-computed RGB values for deck.gl
export const rgbColors = {
    primary: hexToRgb(colors.primary),
    secondary: hexToRgb(colors.secondary),
    success: hexToRgb(colors.success),
    warning: hexToRgb(colors.warning),
    error: hexToRgb(colors.error),
    info: hexToRgb(colors.info),
    accent: hexToRgb(colors.accent),
};