// Filename: Main.tsx
// This file is the main page of the website. 
// It contains the map and the menu bar and routes to App.tsx

import React from 'react';
import MapComponent from "../components/MapComponent";

export default function Main() {
    return (
        <div >
            <MapComponent />
        </div>
      );
    }
    