/*
 * File Name: MapComponent.tsx
 * Description: Initializes the map created in Mapbox studio
 *
 * Author: Maximilian Brown, Tu Do
 * Created: Nov 26 2024
 * Updated: Mar 26 2025
 *
 * Notes:   Make sure you have the Mapbox Access token set in .env
 *          Styling to the map can be changed in Mapbox studio web editor
 */

import React, { useRef, useState, useMemo, useCallback } from "react";
import Map, { MapRef } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { DeckGL } from '@deck.gl/react';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { ScatterplotLayer } from '@deck.gl/layers';
import MarkerDetailsModal from "./MarkerDetailsModal";
import { ref, getBlob } from 'firebase/storage';
import { storage } from "../firebase/firebaseConfig";
import { Button } from "@mui/material";
import { CenterFocusStrong } from "@mui/icons-material";
import { colors } from '../utils/colors';
import { useQuery } from "react-query";

// Center of King County (slightly shifted left and down)
const KING_COUNTY_LONG = -121.90;
const KING_COUNTY_LAT = 47.40;

// Define RGB colors for use with deck.gl
const POSITIVE_COLOR = [42, 157, 143]; // RGB for Success teal (#2A9D8F)
const NEGATIVE_COLOR = [231, 111, 81]; // RGB for Error red (#E76F51)

// Helper function to create RGBA color arrays compatible with deck.gl
const createRGBA = (r: number, g: number, b: number, a: number): [number, number, number, number] => {
    return [r, g, b, a];
};

// Define types for our data structure
interface ParcelData {
    lat: number;
    lon: number;
    roi: number;
    parcel: string;
}

interface CoordinatesData {
    [parcelId: string]: ParcelData;
}

interface MarkerData {
    id: string;
    latitude: number;
    longitude: number;
    roi: number;
    parcel: string;
}

const fetchCoordinates = async (): Promise<MarkerData[]> => {
    const CACHE_KEY = 'immobiliare-coordinates-cache';
    const CACHE_EXPIRY_KEY = 'immobiliare-coordinates-cache-expiry';
    // const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

    // Check cache with expiry
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cacheExpiry = localStorage.getItem(CACHE_EXPIRY_KEY);
    const now = Date.now();

    if (cachedData && cacheExpiry && now < parseInt(cacheExpiry)) {
        console.info("Using cached coordinates data");
        return JSON.parse(cachedData);
    }

    console.info("Fetching coordinates from Firebase Storage");
    try {
        const fileRef = ref(storage, 'coordinates_data.json');
        const blob = await getBlob(fileRef);

        // Optimize by reading as text with proper encoding
        const text = await blob.text();
        const coordinatesObject: CoordinatesData = JSON.parse(text);

        // Convert object to array format for the map component
        const coordinates: MarkerData[] = Object.entries(coordinatesObject)
            .filter(([_, data]) => data.roi !== null)
            .map(([parcelId, data]) => ({
                id: parcelId,
                latitude: data.lat,
                longitude: data.lon,
                roi: data.roi,
                parcel: data.parcel
            }));

        return coordinates;
    } catch (error) {
        console.error("Error fetching coordinates from Firebase Storage:", error);
        throw new Error(`Failed to fetch coordinates: ${String(error)}`);
    }
};

const MapComponent = () => {
    const mapRef = useRef<MapRef>(null);
    const deckRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState<{ id: number, lat: number, lon: number } | null>(null);
    const [viewState, setViewState] = useState({
        longitude: KING_COUNTY_LONG,
        latitude: KING_COUNTY_LAT,
        zoom: 10,
        pitch: 45,
        bearing: 0
    });

    const { data: markers = [], isLoading, error } = useQuery(
        "markers",
        fetchCoordinates,
        {
            staleTime: 300000, // 5 minutes
            cacheTime: 300000, // Keep in memory for 5 minutes
            retry: 2, // Retry failed requests twice
            retryDelay: 1000, // 1 second delay between retries
        }
    );

    console.log("Markers:", markers);

    // Preprocess data once for better performance
    const processedData = useMemo(() => {
        if (!markers || !Array.isArray(markers)) {
            console.warn("Markers is not an array:", markers);
            return [];
        }

        return markers.map((marker: MarkerData) => ({
            position: [Number(marker.longitude), Number(marker.latitude)],
            id: marker.id,
            latitude: Number(marker.latitude),
            longitude: Number(marker.longitude),
            estimated_profit: marker.roi,
            parcel: marker.parcel
        }));
    }, [markers]);

    // Function to handle marker clicks - optimized
    const handleMarkerClick = useCallback((info: any) => {
        if (!info || !info.object) return;

        const id = Number(info.object.id);
        const coordinates = {
            latitude: info.object.latitude,
            longitude: info.object.longitude
        };

        // Update view state to fly to the selected point
        setViewState({
            ...viewState,
            longitude: coordinates.longitude,
            latitude: coordinates.latitude,
            zoom: 18
        });

        setSelectedProperty({ id, lat: coordinates.latitude, lon: coordinates.longitude });
        setModalVisible(true);
    }, [viewState]);

    // Function to zoom back to the starting point - optimized
    const flyToStartZoom = useCallback(() => {
        setViewState({
            ...viewState,
            longitude: KING_COUNTY_LONG,
            latitude: KING_COUNTY_LAT,
            zoom: 10
        });
    }, [viewState]);

    const handleModalClose = useCallback(() => {
        setModalVisible(false);
        setSelectedProperty(null);
    }, []);

    // Define deck.gl layers - optimized for performance
    const layers = useMemo(() => {
        if (!processedData.length) return [];

        const layers = [];
        const zoom = viewState.zoom;

        // Simple boolean checks instead of complex calculations for layer visibility
        const showHeatmap = zoom < 12;
        const showPoints = zoom >= 10;

        if (showHeatmap) {
            // Simplified heatmap layer
            layers.push(
                new HeatmapLayer({
                    id: 'heatmap-layer',
                    data: processedData,
                    getPosition: d => [d.longitude, d.latitude],
                    getWeight: 1, // Constant weight for better performance
                    radiusPixels: 30,
                    intensity: 1,
                    threshold: 0.05,
                    colorRange: [
                        [33, 102, 172, 178],
                        [103, 169, 207, 178],
                        [209, 229, 240, 178],
                        [253, 219, 199, 178],
                        [239, 138, 98, 178],
                        [178, 24, 43, 178]
                    ],
                    updateTriggers: {
                        // Only update on data changes, not on every view state change
                        getPosition: processedData
                    }
                })
            );
        }

        if (showPoints) {
            // Simplified scatterplot layer
            layers.push(
                new ScatterplotLayer({
                    id: 'scatter-plot',
                    data: processedData,
                    pickable: true,
                    opacity: 1,
                    stroked: true,
                    filled: true,
                    radiusScale: 6,
                    radiusMinPixels: 4,
                    radiusMaxPixels: 15,
                    lineWidthMinPixels: 1,
                    getPosition: d => [d.longitude, d.latitude],
                    getRadius: 6, // Fixed size for better performance
                    getFillColor: d => {
                        // Use positive or negative color base with proper typing
                        const baseColor = d.estimated_profit > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR;

                        // Return properly typed RGBA array
                        return createRGBA(
                            baseColor[0],
                            baseColor[1],
                            baseColor[2],
                            178
                        );
                    },
                    getLineColor: [255, 255, 255, 50],
                    onClick: handleMarkerClick,
                    updateTriggers: {
                        // Only update on data changes, not on every view state change
                        getPosition: processedData,
                        getRadius: processedData,
                        getFillColor: processedData
                    }
                })
            );
        }

        return layers;
    }, [processedData, viewState.zoom, handleMarkerClick]);

    // Optimized view state change handler
    const handleViewStateChange = useCallback(({ viewState }: any) => {
        // Update view state without recreating the entire object
        setViewState(viewState);
    }, []);

    if (error) {
        console.error("Error fetching markers:", error);
        return <div>Error loading map data</div>;
    }

    return (
        <div id="map" style={{ height: "100vh" }}>
            {/* Modern animated loading overlay */}
            {isLoading && (
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(15, 23, 42, 0.85)",
                    backdropFilter: "blur(8px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                }}>
                    <div style={{
                        width: "280px",
                        backgroundColor: "rgba(255, 255, 255, 0.95)",
                        borderRadius: "12px",
                        padding: "24px",
                        boxShadow: "0 10px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                    }}>
                        {/* Loading text */}
                        <h3 style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#111827",
                            margin: "0 0 8px 0",
                        }}>
                            Loading Real Estate Data
                        </h3>

                        <p style={{
                            fontSize: "14px",
                            color: "#6b7280",
                            margin: "0 0 16px 0",
                        }}>
                            Fetching 125,000+ coordinate data points from King County
                        </p>

                        {/* Progress bar */}
                        <div style={{
                            width: "100%",
                            height: "8px",
                            backgroundColor: "rgba(59, 130, 246, 0.2)",
                            borderRadius: "4px",
                            overflow: "hidden",
                            marginBottom: "12px",
                            position: "relative",
                        }}>
                            <style>
                                {`
                                @keyframes bounce {
                                    0% { left: 0%; }
                                    50% { left: calc(100% - 40px); }
                                    100% { left: 0%; }
                                }
                                `}
                            </style>
                            <div style={{
                                position: "absolute",
                                width: "40px",
                                height: "8px",
                                backgroundColor: "#3b82f6",
                                borderRadius: "4px",
                                WebkitAnimation: "bounce 2s ease-in-out infinite",
                                animation: "bounce 2s ease-in-out infinite",
                                left: 0,
                            }}></div>
                        </div>
                    </div>
                </div>
            )}

            {selectedProperty && (
                <MarkerDetailsModal
                    open={modalVisible}
                    onClose={handleModalClose}
                    parcelID={selectedProperty.id}
                    lat={selectedProperty.lat}
                    lon={selectedProperty.lon}
                />
            )}

            <DeckGL
                ref={deckRef}
                initialViewState={viewState}
                controller={{
                    inertia: true,
                    scrollZoom: { speed: 0.02 },
                    doubleClickZoom: false
                }}
                layers={layers}
                viewState={viewState}
                onViewStateChange={handleViewStateChange}
                getTooltip={({ object }) => object && `ID: ${object.id}`}
            >
                <Map
                    ref={mapRef}
                    reuseMaps
                    mapStyle="mapbox://styles/mbrown30/cm2wkmevq00ix01pw8klv4sfk"
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                />
            </DeckGL>

            <Button
                onClick={flyToStartZoom}
                startIcon={<CenterFocusStrong />}
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    backgroundColor: 'white',
                    color: colors.primary,
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#f5f5f5',
                    },
                    boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                    borderRadius: '8px',
                }}
            >
                Reset View
            </Button>
        </div>
    );
};

export default MapComponent;
