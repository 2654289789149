// SalesChart.tsx
import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface SaleEvent {
    saleDate: string;
    saleYear: number;
    saleQuarter: string;
    salePrice: number;
    documentDate: string;
}

interface PredictionData {
    startDate: string;
    endDate: string;
    startPredictedValue: number;
    endPredictedValue: number;
    parcelId: string;
    timestamp?: any;
}

interface SalesChartProps {
    salesHistory: SaleEvent[];
    predictionData?: PredictionData;
}

// Helper function to format quarter-year from dateString format of "YYYY.0-QN.0"
const formatQuarterYear = (dateString: string): string => {
    const [year, quarter] = dateString.split('-');
    return `${quarter.replace('.0', '')} ${year.replace('.0', '')}`;
};

const SalesChart: React.FC<SalesChartProps> = ({ salesHistory, predictionData }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Destroy any existing chart
        const chartInstance = Chart.getChart(canvas);
        if (chartInstance) {
            chartInstance.destroy();
        }

        // Sort sales history by date (oldest to newest)
        const sortedSales = [...salesHistory].sort((a, b) => 
            new Date(a.saleDate).getTime() - new Date(b.saleDate).getTime()
        );

        // Extract labels and data for chart
        const labels = sortedSales.map(sale => {
            // Format label based on if it's a quarter (Q1, Q2, etc.) or just a date
            if (sale.saleQuarter) {
                return `${sale.saleQuarter} ${sale.saleYear}`;
            } else {
                // Format date as "MMM YYYY" (e.g., "Jan 2021")
                const date = new Date(sale.saleDate);
                return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
            }
        });
        
        const prices = sortedSales.map(sale => sale.salePrice);

        // Prepare datasets for the chart
        const datasets: any[] = [
            {
                label: 'Sale Price',
                data: prices,
                borderColor: '#3b82f6',
                backgroundColor: 'rgba(59, 130, 246, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.2,
                pointBackgroundColor: '#3b82f6',
                pointRadius: 5,
                pointHoverRadius: 7,
            }
        ];

        // Prepare final labels array
        let finalLabels = [...labels];

        // Add prediction data if available
        if (predictionData) {
            const startLabel = formatQuarterYear(predictionData.startDate);
            const endLabel = formatQuarterYear(predictionData.endDate);
            
            // Add prediction labels if they don't already exist
            if (!finalLabels.includes(startLabel)) {
                finalLabels.push(startLabel);
            }
            
            if (!finalLabels.includes(endLabel)) {
                finalLabels.push(endLabel);
            }
            
            // Sort the labels chronologically
            finalLabels.sort((a, b) => {
                // Sort by year and quarter
                const [aQ, aY] = a.split(' ');
                const [bQ, bY] = b.split(' ');
                if (aY !== bY) return parseInt(aY) - parseInt(bY);
                return aQ.localeCompare(bQ);
            });
            
            // Create prediction dataset with proper typing
            const predictionValues = finalLabels.map(label => {
                if (label === startLabel) {
                    return predictionData.startPredictedValue;
                } else if (label === endLabel) {
                    return predictionData.endPredictedValue;
                }
                return null;
            });
            
            // TypeScript-friendly way to handle null values in dataset
            const predictionDataset = {
                label: 'Predicted Price',
                data: predictionValues.map(value => value === null ? NaN : value), // Use NaN instead of null
                borderColor: '#f97316', // Orange color
                backgroundColor: 'rgba(249, 115, 22, 0.1)',
                borderWidth: 2,
                borderDash: [5, 5], // Dashed line for predictions
                fill: false,
                tension: 0.2,
                pointBackgroundColor: '#f97316',
                pointRadius: 5,
                pointHoverRadius: 7,
                spanGaps: true, // Connect points even with NaN values
            };
            
            datasets.push(predictionDataset);
        }

        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: finalLabels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context: any) {
                                const label = context.dataset.label || '';
                                const value = context.parsed.y;
                                return `${label}: $${value ? value.toLocaleString() : ''}`;
                            },
                        },
                    },
                    legend: {
                        position: 'top',
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Sale Date',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Price ($)',
                        },
                        beginAtZero: false,
                        // Add a formatter to show currency values
                        ticks: {
                            callback: function(value) {
                                return '$' + value.toLocaleString();
                            }
                        }
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, [salesHistory, predictionData]);

    return <canvas ref={canvasRef} />;
};

export default SalesChart;