// MarkerDetailsModal.tsx
import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    IconButton,
    Divider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InfoIcon from '@mui/icons-material/Info';
import { db } from "../firebase/firebaseConfig";
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import SalesChart from './SalesChart';

interface MarkerDetailsModalProps {
    open: boolean;
    onClose: () => void;
    parcelID: number;
    lat: number;
    lon: number;
}

interface PropertyDetails {
    address: string;
    streetType: string;
    districtName: string;
    bedrooms: number;
    bathrooms: number;
    yearBuilt: number;
    squareFootage: number;
    lotSize: number;
    condition: number;
    stories: number;
    heatSystem: string;
    buildingGrade: number;
    totalSales: number;
}

interface SaleEvent {
    saleDate: string;
    saleYear: number;
    saleQuarter: string;
    salePrice: number;
    documentDate: string;
}

interface PredictionData {
    startDate: string;
    endDate: string;
    startPredictedValue: number;
    endPredictedValue: number;
    parcelId: string;
    timestamp?: any;
    roi: number;
}

const MarkerDetailsModal: React.FC<MarkerDetailsModalProps> = ({
    open,
    onClose,
    parcelID,
    lat,
    lon,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [propertyDetails, setPropertyDetails] = useState<PropertyDetails | null>(null);
    const [salesHistory, setSalesHistory] = useState<SaleEvent[]>([]);
    const [latestSale, setLatestSale] = useState<SaleEvent | null>(null);
    const [predictionData, setPredictionData] = useState<PredictionData | null>(null);

    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                const parcelDocRef = doc(db, "Parcels", parcelID.toString());
                const predictionDocRef = doc(db, "Predictions", parcelID.toString());

                const [parcelDocSnap, predictionDocSnap] = await Promise.all([
                    getDoc(parcelDocRef),
                    getDoc(predictionDocRef)
                ]);

                if (!parcelDocSnap.exists()) {
                    console.log("No parcel document found!");
                    return;
                }

                if (!predictionDocSnap.exists()) {
                    console.log("No matching prediction document.");
                    return;
                }

                const data = parcelDocSnap.data();
                console.log("Parcel data:", data);
                const predictionDataFromDB = predictionDocSnap.data();
                console.log("Prediction data:", predictionDataFromDB);

                setPredictionData({
                    startDate: predictionDataFromDB.startDate,
                    endDate: predictionDataFromDB.endDate,
                    startPredictedValue: predictionDataFromDB.startPredictedValue,
                    endPredictedValue: predictionDataFromDB.endPredictedValue,
                    parcelId: predictionDataFromDB.parcelId,
                    timestamp: predictionDataFromDB.timestamp,
                    roi: predictionDataFromDB.endPredictedValue - predictionDataFromDB.startPredictedValue
                });

                const bathFullCount = data.BathFullCount || 0;
                const bathHalfCount = data.BathHalfCount || 0;
                const bath3qtrCount = data.Bath3qtrCount || 0;
                const totalBathrooms = bathFullCount + (bathHalfCount * 0.5) + (bath3qtrCount * 0.75);

                setPropertyDetails({
                    address: data.Address || 'N/A',
                    streetType: data.StreetType || 'N/A',
                    districtName: data.DistrictName || 'N/A',
                    bedrooms: data.Bedrooms || 0,
                    bathrooms: totalBathrooms,
                    yearBuilt: data.YrBuilt || 0,
                    squareFootage: data.SqFtTotLiving || 0,
                    lotSize: data.SqFtLot || 0,
                    condition: data.Condition || 0,
                    stories: data.Stories || 0,
                    heatSystem: data.HeatSystem || 'N/A',
                    buildingGrade: data.BldgGrade || 0,
                    totalSales: data.totalSales || 0,
                });

                await fetchSalesHistory(parcelID.toString());
            } catch (error) {
                console.error("Error fetching property details:", error);
            }
        };

        if (open && parcelID) {
            fetchPropertyDetails();
        }
    }, [open, parcelID]);

    const fetchSalesHistory = async (parcelId: string) => {
        try {
            const salesCollectionRef = collection(db, `Parcels/${parcelId}/Sales`);
            const salesSnapshot = await getDocs(query(salesCollectionRef));

            if (salesSnapshot.empty) {
                console.log("No sales history found.");
                return;
            }

            const salesData = salesSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    saleDate: doc.id,
                    saleYear: data.SaleYear || 0,
                    saleQuarter: data.SaleQuarter || '',
                    salePrice: data.SalePrice || 0,
                    documentDate: data.DocumentDate || '',
                } as SaleEvent;
            });

            const sortedSales = salesData.sort((a, b) => {
                return new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime();
            });

            setSalesHistory(sortedSales);

            if (sortedSales.length > 0) {
                setLatestSale(sortedSales[0]);
            }

        } catch (error) {
            console.error("Error fetching sales history:", error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            hideBackdrop
            fullScreen={isSmallScreen}
            sx={{
                position: isSmallScreen ? 'fixed' : 'absolute',
                right: isSmallScreen ? 0 : 'unset',
                top: 0,
                maxWidth: isSmallScreen ? '100%' : '50%',
                width: isSmallScreen ? '100%' : '50%',
                height: '100%',
                margin: 0,
                '& .MuiPaper-root': {
                    borderRadius: isSmallScreen ? 0 : 8,
                    width: '100%',
                    height: '100%',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    overflowY: 'auto',
                    border: '1px solid #e5e7eb',
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                    color: '#111827',
                    padding: '12px',
                    paddingLeft: '24px',
                    borderBottom: '1px solid #e5e7eb',
                }}
            >
                Property Details
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '24px', marginTop: '24px', display: 'flex', flexDirection: 'column', gap: 2 }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="h5" sx={{ fontWeight: 600, color: '#111827' }}>
                        {propertyDetails?.address || 'Loading address...'}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: '#6b7280' }}>
                        {propertyDetails?.districtName || ''} • Built in {propertyDetails?.yearBuilt || 'N/A'}
                    </Typography>
                </Box>

                {predictionData && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: '16px',
                            borderRadius: '8px',
                            backgroundColor: predictionData.roi > 0 ? 'rgba(16, 185, 129, 0.1)' : 'rgba(239, 68, 68, 0.1)',
                            border: `1px solid ${predictionData.roi > 0 ? 'rgba(16, 185, 129, 0.3)' : 'rgba(239, 68, 68, 0.3)'}`,
                            marginTop: '8px',
                            marginBottom: '8px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 600,
                                    color: predictionData.roi > 0 ? '#10B981' : '#EF4444',
                                }}
                            >
                                Predicted ROI: {predictionData.roi > 0 ? '+' : ''}{predictionData.roi.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: predictionData.roi > 0 ? '#10B981' : '#EF4444',
                                    borderRadius: '4px',
                                    padding: '4px 8px',
                                }}
                            >
                                <Typography variant="body2" sx={{ color: 'white', fontWeight: 500 }}>
                                    {predictionData.roi > 0 ? 'Potential Gain' : 'Potential Loss'}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                            <Box>
                                <Typography variant="body2" sx={{ color: '#6b7280', fontWeight: 500 }}>
                                    Initial Value
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 600, color: '#111827' }}>
                                    {predictionData.startPredictedValue.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#6b7280' }}>
                                    {predictionData.startDate.replace('.0-Q', ' Q').replace('.0', '')}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component="span" sx={{
                                    width: '24px',
                                    borderTop: '1px solid #d1d5db',
                                    margin: '0 8px'
                                }} />
                                <ArrowRightAltIcon sx={{ color: '#6b7280' }} />
                                <Box component="span" sx={{
                                    width: '24px',
                                    borderTop: '1px solid #d1d5db',
                                    margin: '0 8px'
                                }} />
                            </Box>
                            <Box sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" sx={{ color: '#6b7280', fontWeight: 500 }}>
                                    Projected Value
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 600, color: '#111827' }}>
                                    {predictionData.endPredictedValue.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#6b7280' }}>
                                    {predictionData.endDate.replace('.0-Q', ' Q').replace('.0', '')}
                                </Typography>
                            </Box>
                        </Box>

                        <Typography variant="caption" sx={{ color: '#6b7280', display: 'flex', alignItems: 'center' }}>
                            <InfoIcon sx={{ fontSize: '14px', marginRight: '4px' }} />
                            Values predicted by Pangeon's proprietary machine learning model trained on historical property data
                        </Typography>
                    </Box>
                )}

                <Divider sx={{ borderColor: '#e5e7eb' }} />

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#6b7280' }}>Bedrooms</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500, color: '#111827' }}>
                            {propertyDetails?.bedrooms || 'N/A'}
                        </Typography>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#6b7280' }}>Bathrooms</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500, color: '#111827' }}>
                            {propertyDetails?.bathrooms.toFixed(1) || 'N/A'}
                        </Typography>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#6b7280' }}>Square Footage</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500, color: '#111827' }}>
                            {propertyDetails?.squareFootage?.toLocaleString() || 'N/A'} sq ft
                        </Typography>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#6b7280' }}>Lot Size</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500, color: '#111827' }}>
                            {propertyDetails?.lotSize?.toLocaleString() || 'N/A'} sq ft
                        </Typography>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#6b7280' }}>Condition</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500, color: '#111827' }}>
                            {propertyDetails?.condition || 'N/A'} / 5
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ marginTop: 3 }}>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#111827', marginBottom: 2 }}>
                        Sales History ({salesHistory.length} sales)
                    </Typography>
                    <Box sx={{ height: 300 }}>
                        {salesHistory.length > 0 ? (
                            <SalesChart salesHistory={salesHistory} predictionData={predictionData || undefined} />
                        ) : (
                            <Typography variant="body2" sx={{ color: '#6b7280', textAlign: 'center', marginTop: 10 }}>
                                No sales history available
                            </Typography>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default MarkerDetailsModal;